import React from 'react'
import './css/footer.css'
import { Outlet, Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
    <footer>
        <div className='container'>
            <div className='links'>
                <div className='links_columna'>
                    <p>Somos una empresa especializada en el desarrollo web, ofrecemos soluciones personalizadas, modernas y funcionales que destacan la identidad única de cada proyecto</p>
                    <div className='social'>
                        <div className='social_icon'><i class="fi fi-brands-facebook"></i></div>
                        <div className='social_icon'><i class="fi fi-brands-instagram"></i></div>
                        <div className='social_icon'><i class="fi fi-brands-whatsapp"></i></div>
                    </div>                                        
                    <Link to="/" className="links_menu" >Términos y condiciones </Link>
                    <Link to="/" className="links_menu" >Políticas de privacidad </Link>                                   
                </div>
                <div className='links_columna'>
                    <h3>Menú</h3>
                    <Link className="links_menu" to="/" title="">Inicio</Link>
                    <Link className="links_menu" to="/nosotros" title="">Nosotros</Link>
                    <Link className="links_menu" to="/" title="">Servicios</Link>
                    <Link className="links_menu" to="/cotizador" title="">Cotizador</Link>
                    <Link className="links_menu" to="/contacto" title="">Contacto</Link>
                </div>
                
                <div className='links_columna'>
                    <h3>Servicios</h3>
                    <Link className="links_menu" to="/pagina-web" title="">Página web</Link>
                    <Link className="links_menu" to="/tienda-en-linea" title="">Tienda en linea</Link>                    
                    <Link className="links_menu" to="/auditoria" title="">Auditoría web</Link>
                    <Link className="links_menu" to="/auditoria" title="">Soporte</Link>
                    
                </div>
                <div className='links_columna'>
                    <h3>Soluciones</h3>
                    <Link className="links_menu" to="/hosting-correo" title="">Hosting y Correo</Link>
                    <Link className="links_menu" to="/desarrollo-web" title="">Desarrollo de software</Link>
                    <Link className="links_menu" to="/gestion-web" title="">Gestion de contenido web</Link>
                    <Link className="links_menu" to="/auditoria" title="">Auditoría web</Link>
                    
                </div>
            </div>
            <div className='terms'> 
                <label>Copyright © 2024 UNIVERSEPAGE - Soluciones Web</label>
            </div>
        </div>
    </footer>

    <footer>
        <div className='container'>
            <div style={{display: 'flex'}}>
            <div className='links_columna'>
                    <p>Somos una empresa especializada en el desarrollo web, ofrecemos soluciones personalizadas, modernas y funcionales que destacan la identidad única de cada proyecto</p>
                    <div className='social'>
                        <div className='social_icon'><i class="fi fi-brands-facebook"></i></div>
                        <div className='social_icon'><i class="fi fi-brands-instagram"></i></div>
                        <div className='social_icon'><i class="fi fi-brands-whatsapp"></i></div>
                    </div>                                        
                    <Link to="/" className="links_menu" >Términos y condiciones </Link>
                    <Link to="/" className="links_menu" >Políticas de privacidad </Link>                                   
                </div>
                 <div className='links'>
                
                <div className='links_columna'>
                    <h3>Menú</h3>
                    <Link className="links_menu" to="/" title="">Inicio</Link>
                    <Link className="links_menu" to="/nosotros" title="">Nosotros</Link>
                    <Link className="links_menu" to="/" title="">Servicios</Link>
                    <Link className="links_menu" to="/cotizador" title="">Cotizador</Link>
                    <Link className="links_menu" to="/contacto" title="">Contacto</Link>
                </div>
                
                <div className='links_columna'>
                    <h3>Servicios</h3>
                    <Link className="links_menu" to="/pagina-web" title="">Página web</Link>
                    <Link className="links_menu" to="/tienda-en-linea" title="">Tienda en linea</Link>                    
                    <Link className="links_menu" to="/auditoria" title="">Auditoría web</Link>
                    <Link className="links_menu" to="/auditoria" title="">Soporte</Link>
                    
                </div>
                <div className='links_columna'>
                    <h3>Soluciones</h3>
                    <Link className="links_menu" to="/hosting-correo" title="">Hosting y Correo</Link>
                    <Link className="links_menu" to="/desarrollo-web" title="">Desarrollo de software</Link>
                    <Link className="links_menu" to="/gestion-web" title="">Gestion de contenido web</Link>
                    <Link className="links_menu" to="/auditoria" title="">Auditoría web</Link>
                    
                </div>
            </div>

            </div>
            <div className='terms'> 
                <label>Copyright © 2024 UNIVERSEPAGE - Soluciones Web</label>
            </div>
        </div>
    </footer>
    <Outlet />
    </>
  )
}

import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./css/styles.css"; // Archivo CSS para las transiciones
import faq from './assets/images/faq.webp'

const FAQ = ({ icon, question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const toggleAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <div className="faq-item">
      <div className="question" onClick={toggleAnswer}>
        <div className="icon_faq">{icon}</div>
        <div>{question}</div>
      </div>
      <CSSTransition in={showAnswer} timeout={300} classNames="answer" unmountOnExit>
        <div className="answer">{answer}</div>
      </CSSTransition>
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    {
      icon: <i class="fi fi-rr-angle-circle-down"></i>,
      question: "¿Cómo empiezo?",
      answer: " Para empezar el proceso, nos gustaría recopilar información clave que nos ayudará a entender tus necesidades y expectativas. Una vez que tengamos esta información, estaremos en una excelente posición para ofrecerte una propuesta personalizada que se ajuste a tus objetivos.",
    },
    {
        icon: <i class="fi fi-rr-angle-circle-down"></i>,
        question: "¿Qué recursos debo compartir para la elaboración de un sitio web?",
        answer: "Es crucial recopilar información clave para entender tus necesidades y expectativas, algunas de la información necesaria es: objetivos, cuales son tus objetivos del sitio, a que publico es dirigido, contenido como texto, imágenes y vídeo (opcional), logotipo (En caso de contar con uno), presupuesto y plazos. Esta información ayudará a establecer una base sólida para el desarrollo del proyecto y garantizará que el producto final cumpla con tus expectativas.",
    },
    {
      icon: <i class="fi fi-rr-angle-circle-down"></i>,
      question: "¿En cuánto tiempo tendré mi sitio web funcionando?",
      answer: "El tiempo necesario para tener tu sitio web completamente funcional puede variar según la complejidad y el alcance del proyecto. Para tener una estimación más precisa, sería útil conocer más detalles sobre lo que tienes en mente. Elementos como el diseño, las funcionalidades específicas que deseas incluir, la cantidad de contenido y otros factores pueden afectar el tiempo de desarrollo.",
    },
    {
        icon: <i class="fi fi-rr-angle-circle-down"></i>,
        question: "¿Qué es el dominio y hosting (Hospedaje)?",
        answer: "El dominio es la dirección única que utilizan las personas para acceder a un sitio web, mientras que el hosting es el servicio que proporciona el espacio y los recursos necesarios para almacenar y mostrar ese sitio web en Internet. Ambos son esenciales para tener un sitio web funcional y accesible en línea.",
    },
    {
        icon: <i class="fi fi-rr-angle-circle-down"></i>,
        question: "¿Usan gestores de contenido?, ¿Puedo administrar mi sitio web?",
        answer: " Sí, utilizamos WordPress como uno de nuestros gestores de contenido. WordPress es una plataforma popular y versátil que nos permite crear sitios web personalizados y funcionales para nuestros clientes. Sin embargo, también evaluamos otras opciones según las necesidades específicas de cada proyecto.",
    },
    {
        icon: <i class="fi fi-rr-angle-circle-down"></i>,
        question: "¿Puedo hacer cambios en mi sitio web?",
        answer: "Sí, definitivamente puedes realizar cambios en el contenido de tu sitio. Queremos asegurarnos de que tengas el control total sobre tu plataforma. También brindamos servicios de gestión de contenido web personalizados. Nuestro equipo de expertos puede encargarse de la creación, actualización y optimización del contenido de tu sitio para asegurarnos de que cumpla con tus objetivos y necesidades.",
    },
    {
        icon: <i class="fi fi-rr-angle-circle-down"></i>,
        question: "¿Hay limite en la cantidad de contenido que puedo publicar en mi sitio web?",
        answer: "No hay un límite estricto en la cantidad de contenido que puedes publicar en tu sitio web. Queremos que tengas la libertad de compartir la información que consideres importante para tu audiencia. Nuestra plataforma está diseñada para adaptarse a tus necesidades y crecer junto con tu negocio. Si en algún momento tienes preguntas específicas sobre la capacidad de almacenamiento o cualquier otro aspecto relacionado con la gestión de contenido, no dudes en ponerte en contacto con nosotros.",
    },
    {
      icon: <i class="fi fi-rr-angle-circle-down"></i>,
      question: "¿Qué métodos de pago aceptan?",
      answer: "Actualmente, aceptamos pagos a través de transferencias bancarias y depósitos. Estos métodos nos permiten procesar pagos de manera segura y eficiente. Si necesitas información adicional sobre los detalles de pago o si tienes alguna preferencia específica, por favor házmelo saber.",
    }
    
  ];

  return (
    <section className="faq">
      <div className="container">
        <div class="preguntas_frecuentes_title">
                <h2>Preguntas frecuentes</h2>
                <p>Aquí encontrarás las respuestas a algunas de las preguntas más comunes que nos hacen nuestros clientes.</p>
            </div>
        <div className="preguntas_frecuentes">
            
            <div className="preguntas_frecuentes_faq">
                
                {faqs.map((faq, index) => (
                    <FAQ key={index} icon={faq.icon} question={faq.question} answer={faq.answer} />
                ))}
            </div>
            <div className="preguntas_frecuentes_text">
                <div className="preguntas_frecuentes_img">
                    <img src={faq} alt="preguntas frecuentes universepage" />
                </div>
                
                <h2>¿Tienes alguna pregunta?</h2>
                <p>Si tienes alguna otra pregunta que no se responde aquí, no dudes en ponerte en contacto con nuestro equipo de atención al cliente.
                </p>
                
            </div>

        </div>
         
      </div>
    </section>

  );
};

export default FAQSection;

import React, { useState } from 'react';
import '../css/prueba.css';
import { Outlet, Link } from "react-router-dom";

const Pruebas = () => {
    const [isMenuOpen, setisMenuOpen] = useState(false);
    const [isSubmenu, setIsSubmenu] = useState(null);

    const toggleMenu = () => {
        setisMenuOpen(!isMenuOpen);    
    };
    

    const toggleSubmenu = (index) => {
        setIsSubmenu((prevState) => (prevState === index ? null : index));
    };


return (
    <>
    <section>

    <div className='d-none d-sm-none d-md-block'>
    <nav className="navbar">
        <div className='container'>           
                <div className='navbar-content'>
                    <div className="navbar-brand">
                        <div className='box_menu_img'>
                            <div className='menu_img_show logo'> <Link to="/" className="navbar-link" >UNIVERSEPAGE</Link>  </div>
                        </div>
                        <div className='box_menu_right'>
                            <button className="navbar-toggler" onClick={toggleMenu}>
                                <i className="fi fi-rr-bars-staggered"></i>   
                            </button>
                        </div>                        
                    </div>                
                    <ul className={`navbar-menu menu_items ${isMenuOpen ? 'navbar-menu-open' : ''}`}>                                                
                        <div className='opc_menu'>
                            <div className='menu_img_show'>UNIVERSEPAGE</div>           
                                <div onClick={toggleMenu} className='menu_cerrar_option'> 
                                <div className='btn_cerrar_option'><i class="fi fi-br-cross"></i></div>
                            </div>
                        </div>
                            <div className='main_menu'>                        
                            <li className="navbar-item " >
                                <Link to="/" className="navbar-link" onClick={toggleMenu}>Inicio</Link>                                           
                            </li>
                            <li className="navbar-item">
                            <Link to="/nosotros "  onClick={toggleMenu}>Nosotros </Link>                             
                            </li>
                            <li className="navbar-item serviceOpc">
                                <a href="#services" className="navbar-link" >Servicios <i class="fa-solid fa-caret-down"></i></a>                
                                <div class="submenu" >
                                    <div class="submenu_list">
                                        <Link to="/pagina-web"  onClick={toggleMenu}>Página web </Link>
                                        <Link to="/tienda-en-linea" onClick={toggleMenu}>Tienda en línea </Link>
                                        <Link to="/desarrollo-web" onClick={toggleMenu}>Desarrollos web personalizados </Link>
                                        <Link to="/hosting-dominio-correo" onClick={toggleMenu}>Dominio, Hosting y Correo </Link>
                                        <Link to="/gestion-web" onClick={toggleMenu}>Administración de sitios </Link>
                                        <Link to="/auditoria" onClick={toggleMenu}>Auditoría web </Link>
                                    </div>
                                </div>
                                </li>                    
                                <li className="navbar-item">
                                <Link to="/cotizador" onClick={toggleMenu}>Cotizador </Link>
                                </li>
                                <li className="navbar-item">
                                <Link to="/contacto" onClick={toggleMenu}>Contacto </Link>
                                </li>
                                <li className='navbar-item wp-btn'>
                                    <a href="https://wa.me/5216122186230?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel='noopener noreferrer' aria-label="Contactar por WhatsApp" style={{color:'#fff'}}>
                                    WhatsApp
                                    <i class="fa-brands fa-whatsapp"></i></a>                                    
                                </li>
                            </div>                                        
                        </ul>        
                    </div>                          
                </div>
            </nav>
    </div>
    <div className='d-block d-sm-block d-md-none'>
        <div className='menu_activo'>
            <div className='container'>
                <div className='menu_responsive_content'>
                    <div>
                        <Link href="/"><div class="menu_img_show">UNIVERSEPAGE</div></Link>
                    </div>
                    <div className='btn_menu_responsive'>
                        <button onClick={() => setIsSubmenu(null)}><i class="fi fi-br-cross"></i></button>
                    </div>
                </div>

                <div className='submenu_list'>
                    <ul>
                        <li>
                            <Link onClick={() => toggleSubmenu(1)}>Marítimo <i class="fi fi-rr-caret-down"></i></Link>
                            {isSubmenu === 1 && (
                                <ul className='submenu_item'>
                                    <li>Chartering</li>
                                    <li>Feedering nacional</li>
                                    <li> proyecto</li>
                                </ul>
                            )}
                        </li>
                        <li>
                            <Link onClick={() => toggleSubmenu(2)}>Terrestre <i class="fi fi-rr-caret-down"></i></Link>
                            {isSubmenu === 2 && (
                                <ul className='submenu_item'>
                                    <li>Contract Logistics</li>
                                    <li>Freight Forwarding</li>
                                    <li>Agente Naviero</li>
                                </ul>
                            )}
                        </li>
                        <li>
                            <Link onClick={() => toggleSubmenu(3)}>Multimodal <i class="fi fi-rr-caret-down"></i></Link>
                            {isSubmenu === 3 && (
                                <ul className='submenu_item'>
                                    <li>Servicio Multimodal</li>
                                    <li>Circuito Intermodal</li>
                                </ul>
                            )}
                        </li>
                        <li>
                            <Link to="/sostenibilidad">Sostenibilidad <i class="fi fi-rr-caret-down"></i></Link>
                        </li>
                        <li>
                            <Link to="/itinerario">Itinerario </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    

    </section>
    <Outlet />
    </> 
);
};

export default Pruebas;
import React from 'react'
import { Outlet, Link } from "react-router-dom";

export default function Servicios() {
  return (
    <>
    	<section class="services" id="services">
		<div class="container">
			<div class="">
				<h2>Servicios y soluciones integradas</h2>
			</div>
			
			<div class="services_list">				
				<div class="box_service">
					<Link to="/pagina-web">
						<div class="service_title">
							<div class="service_icon"> <i class="fa-regular fa-file-code" ></i></div>
							<div><strong style={{fontWeight:'500'}}>Página web</strong></div>
						</div>
						<div class="service_text">
							<p>Creamos sitios web personalizados, con un enfoque especial en la optimización para mejorar tanto la experiencia del usuario como la funcionalidad. </p>
						</div>	
					</Link>	
				</div>			
				<div class="box_service">
					<Link to="/tienda-en-linea">
						<div class="service_title">
							<div class="service_icon"> <i class="fa-solid fa-cart-shopping"></i></div>
							<div><strong style={{fontWeight:'500'}}>Tienda en línea</strong></div>
						</div>
						<div class="service_text">
							<p>Con un enfoque centrado en la seguridad y la funcionalidad, le brindamos una plataforma de comercio electrónico robusta que le permite expandir su alcance y aumentar sus ventas.</p>
						</div>
					</Link>	
				</div>
				<div class="box_service">
					<Link to="/desarrollo-web">
						<div class="service_title">
							<div class="service_icon"> <i class="fa-solid fa-laptop-code"></i></div>
							<div><strong style={{fontWeight:'500'}}>Desarrollos web personalizados</strong></div>
						</div>
						<div class="service_text">
							<p> Utilizamos las últimas tecnologías y las mejores prácticas de la industria para diseñar soluciones robustas y escalables que optimicen la eficiencia de su sistema y mejoren la experiencia del usuario. </p>
						</div>
					</Link>	
				</div>	
			</div>
			<div class="services_list">
				<div class="box_service">
					<Link to="/hosting-correo">
						<div class="service_title">
							<div class="service_icon"> <i class="fa-solid fa-server"></i></div>
							<div><strong style={{fontWeight:'500'}}>Dominio, Hosting y Correo</strong></div>
						</div>
						<div class="service_text">
							<p>Nuestro servicio de dominio, hosting y correo le proporciona una solución completa y confiable a través de nuestro proveedor de confianza.</p>
						</div>	
					</Link>		
				</div>
				<div class="box_service">
					<Link to="/gestion-web">
						<div class="service_title">
							<div class="service_icon"> <i class="fa-solid fa-clipboard-list"></i></div>
							<div><strong style={{fontWeight:'500'}}>Administración de sitios</strong></div>
						</div>
						<div class="service_text">
							<p>Desde el desarrollo inicial hasta las actualizaciones continuas y el soporte técnico, nos encargamos de cada aspecto para garantizar un rendimiento óptimo y una experiencia de usuario excepcional.</p>
						</div>
					</Link>	
				</div>
				<div class="box_service">
					<Link to="/auditoria">
						<div class="service_title">
							<div class="service_icon"> <i class="fa-solid fa-magnifying-glass-chart"></i></div>
							<div><strong style={{fontWeight:'500'}}>Auditoría web</strong></div>
						</div>
						<div class="service_text">
							<p>Nuestra auditoría web exhaustiva y detallada le proporcionará una comprensión profunda del rendimiento de su sitio web, identificando áreas clave para mejorar la visibilidad, la usabilidad y la optimización para motores de búsqueda.</p>
						</div>
					</Link>	
				</div>	
			</div>
		</div>
	</section>
	<Outlet />
	</>
  )
}

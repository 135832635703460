import React, {useState} from 'react'
import '../../css/pagina_web.css'
import '../../css/tienda.css'
import tienda_en_linea from '../../assets/images/tienda_en_linea.jpg'
import html from '../../assets/images/html.png'
import css from '../../assets/images/css.png'
import js from '../../assets/images/js.png'
import react from '../../assets/images/react.png'
import node from '../../assets/images/node.png'
import postgresql from '../../assets/images/postgresql.png'
import mysql from '../../assets/images/mysql.png'
import tienda1 from '../../assets/images/tienda-con-pasarela.webp'
import tienda2 from '../../assets/images/tienda.jpg'
import tienda3 from '../../assets/images/tienda-catalogo.jpg'
import auditoria from '../../assets/images/auditoria.jpg'

import facturacion from '../../assets/images/pagina-web/seguridad-informatica.png'
import helpdesk from '../../assets/images/pagina-web/correo-electronico.png'
import gestion from '../../assets/images/pagina-web/solucion-de-problemas.png'
import migracion from '../../assets/images/pagina-web/capacitacion.png'
import Footer from '../../Footer'
import axios from 'axios';
export default function TiendaEnLinea() {
    const [nombre, setNombre] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [telefono, setTelefono] = useState('');
    const [servicio, setServicio] = useState('');
	const [email, setEmail] = useState('');
	const [mensaje, setMensaje] = useState('');
	const [aceptoPoliticas, setAceptoPoliticas] = useState(true);
    const [alert, setAlert] = useState(false);
    //await axios.post('http://localhost:3001/enviar-correo', {

	const enviarCorreo = async () => {

        try {
            await axios.post('https://3cwma7t8s4.execute-api.us-east-1.amazonaws.com/prod/webservice', {
                nombre,
                empresa,
                telefono,
				servicio,
                email,
                mensaje,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });
		
    		
            // Limpiar campos después de enviar el correo
            setNombre('');
            setEmpresa('');
            setTelefono('');
			setServicio('');
            setEmail('');
            setMensaje('');
    
            setAlert(true);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Error al enviar el correo');
        }
    };
  return (
    <>
    <section className='headers'>
        <div className='container'>
            <div className='title'>
                <h1>Tienda en línea</h1>
                <p>Nuestro propósito es lo que nos hace levantarnos cada mañana, <br/> y esforzarnos por ofrecer un futuro más digital. </p>
            </div>
        </div>
    </section>
    <section>
      <div className='container'>

          <div class="content_about">
              <div class="about_img">
                  <img src={tienda_en_linea} alt='About univesepage' />
              </div>
              <div class="about_text_box">
                  <div class="about_text">
                      <h2 style={{textAlign:'initial'}}>TRANSFORMANDO IDEAS EN SOLUCIONES DIGITALES</h2>
                      <h4>Tienda en línea</h4>
                      <p>Diseñamos y desarrollamos tiendas en linea para satisfacer las necesidades específicas de cada cliente. Nuestro equipo de expertos se sumerge en cada detalle para asegurarse de que su presencia en la web refleje la identidad única de su negocio.</p>
                      <p>La implementación de metodologías ágiles y las mejores prácticas de desarrollo nos permiten ofrecer productos de alta calidad en plazos eficientes. Estamos aquí para transformar tus ideas en soluciones digitales impactantes y escalables. </p>
                      
                      <a href="#categorias" class="btn_about"  aria-label="categorias universepage">Ver categorias</a>
                  </div>
                  
              </div>
          </div>
      </div>
  </section>

  <section className='tipos_tienda' id='categorias'>
      <div className='container'>
            <div>
                <h2>¿CÓMO SABER LO QUE NECESITO?</h2>            
                <p>Las tiendas en línea web se pueden categorizar de diversas maneras según su estructura, propósito y funcionalidades. Algunas de las categorías comunes son:</p>
            </div>
          <div className='pagina_web'>
              <div className='box_pagina_web'>
                  <div className='card_pagina_web'> 
                      <div className='card_pagina_web_img'>
                          <img src={tienda2} alt='pagina web ona page' />
                      </div>
                      <div className='card_pagina_web_title'>
                          <h2>Tienda con pasarela de pago</h2>
                      </div>
                      <div className='card_pagina_web_text'>
                          <p>Son tiendas en línea estándar que ofrecen una variedad de productos o servicios. Pueden tener una amplia gama de categorías y características, como carritos de compra, opciones de pago variadas, y más.</p>
                         {/* <p>Características: Secciones organizadas de manera vertical u horizontal, menús de navegación que llevan a diferentes partes de la misma página.</p>*/}
                      </div>
                      <div className='card_btn'>
                      <a href="https://wa.me/5216122186230?text=Me%20interesa%20una%20cotizaci%C3%B3n%20Tienda%20con%20pasarela"  className="btn_serviceweb" target="_blank" rel='noopener noreferrer' aria-label="Contactar por WhatsApp" style={{color:'#fff'}}>
                        Cotizar ahora
                        </a>
                      </div>
                  </div>
              </div>
              <div className='box_pagina_web'>
                  <div className='card_pagina_web'> 
                      <div className='card_pagina_web_img'>
                          <img src={tienda1} alt='pagina web ona page' />
                      </div>
                      <div className='card_pagina_web_title'>
                          <h2>Tipo catálogo</h2>
                      </div>
                      <div className='card_pagina_web_text'>
                          <p>Se centran en productos o servicios específicos que requieran una cotización por otro medio. Por ejemplo, un producto con botón directo a WhatsApp</p>
                          {/*<p>Características: Menús de navegación que dirigen a diversas secciones, generalmente con una estructura jerárquica de páginas.</p>*/}
                      </div>
                      <div className='card_btn'>
                      <a href="https://wa.me/5216122186230?text=Me%20interesa%20una%20cotizaci%C3%B3n%20Tienda%20tipo%20catálogo"  className="btn_serviceweb" target="_blank" rel='noopener noreferrer' aria-label="Contactar por WhatsApp" style={{color:'#fff'}}>
                      Cotizar ahora
                         </a>
                          
                      </div>

                  </div>

              </div>
            
              <div className='box_pagina_web'>
                  <div className='card_pagina_web'> 
                      <div className='card_pagina_web_img'>
                          <img src={tienda3} alt='pagina web ona page' />
                      </div>
                      <div className='card_pagina_web_title'>
                          <h2>Mercados en línea</h2>
                      </div>
                      <div className='card_pagina_web_text'>
                          <p>Plataformas que permiten a vendedores individuales ofrecer sus productos o servicios. Ejemplo: Mercado libre, eBay o Amazon.</p>                          
                      </div>
                      <div className='card_btn'>
                      <a href="https://wa.me/5216122186230?text=Me%20interesa%20una%20cotizaci%C3%B3n%20Tienda%20tipo%20mercado"  className="btn_serviceweb" target="_blank" rel='noopener noreferrer' aria-label="Contactar por WhatsApp" style={{color:'#fff'}}>
                      Cotizar ahora
                         </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>


  </section>

  <section className='beneficios'>
		<div className='container'>
			<div class="beneficios_planes_title">
				<h2>Todos los planes incluyen </h2>
                <p>Nuestros desarrollos web ofrecen diferentes beneficios que pueden facilitar tu presencia </p>
			</div>
			<div className='beneficios_planes'>
				<div className='box_beneficios_planes'>
					<div><img src={facturacion} alt='Help desk'/></div>
					<div className='box_beneficios_title'>Certificado de seguridad SSL</div>
					<div>Nuestros servicios incluyen un certificado de seguridad gratuito.</div>
				</div>
				<div className='box_beneficios_planes'>
					<div><img src={helpdesk } alt='Help desk'/></div>					
					<div className='box_beneficios_title'>Correo empresarial</div>
					<div>Tus cuentas de correo electrónico personalizado desde cualquier lugar, rápido y fácil. </div>
				</div>
				<div className='box_beneficios_planes'>
					<div><img src={gestion} alt='Help desk'/></div>
					<div className='box_beneficios_title'>Soporte</div>
					<div>Personal con experiencia técnica y ejecutiva para la gestion de tu infraestructura.</div>
				</div>
				<div className='box_beneficios_planes'>
					<div><img src={migracion} alt='Help desk'/></div>
					<div className='box_beneficios_title'>Capacitación</div>
					<div>Te brindaremos sesiones personalizadas donde obtendras conocimientos necesarios para comprender tu sitio.</div>
				</div>

			</div>

		</div>
	</section>
    <section>
		<div class="container">
			<div class="contact">
				<div class="contact_info">
                    <img src={auditoria} alt='About univesepage' />
					<div>
                    <p><i class="fi fi-rr-angle-circle-right"></i> Diseño UX/UI.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Optimización de velocidad de carga.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Formulario de contacto.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> SEO básico.</p>
                        <p><i class="fi fi-rr-angle-circle-right"></i> Conexión con redes sociales.</p>
					</div>					
				</div>
				<div class="formulario">
						<div>
							<h2>Envíanos tus dudas o comentarios</h2>
							<p>Completa el formulario de contacto a continuación y cuéntanos lo que necesitas. Estaremos encantados de responder a tus preguntas, proporcionarte asistencia personalizada o brindarte más detalles sobre nuestros servicios.</p>
						</div>
						{alert &&
							<div className='alertMessage'>
								<label>¡Tu formulario ha sido enviado con éxito!</label>
								<p> Nuestro equipo está revisando la información, un agente se pondrá en contacto contigo pronto.</p>
							</div>
						}						
						<div class="form-group">
							<input
								type="text"
								placeholder="Tu nombre"
								value={nombre}
								onChange={(e) => setNombre(e.target.value)}
							/>
							<input
								type="text"
								placeholder="Nombre de tu empresa (opcional)"
								value={empresa}
								onChange={(e) => setEmpresa(e.target.value)}
							/>
							
						</div>
						<div class="form-group">
							<input type='text'
								value={telefono}
								onChange={(e) => setTelefono(e.target.value)} 
								placeholder="Teléfono" />

							<select value={servicio}
								onChange={(e) => setServicio(e.target.value)}>
								<option>¿Qué servicio te interesa?</option>
								<option>Página web</option>
								<option>Tienda en línea</option>
								<option>Desarrollos web personalizados</option>
								<option>Dominio, Hosting y Correo</option>
								<option>Gestión de contenido web</option>
								<option>Auditoría web</option>
								<option>Otro tema</option>
								<option>No lo tengo definido</option>								
							</select>
						</div>
						<div>
							<input type="email"
								placeholder="Email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								/>
						</div>
						<div>
							<textarea 
								placeholder="¿Cómo podemos ayudarte?"
								value={mensaje}
								onChange={(e) => setMensaje(e.target.value)}
								>
							</textarea>
						</div>
						<div className='term-cond'>
							<input type="checkbox" 
							name=""
							checked={aceptoPoliticas}
							onChange={() => setAceptoPoliticas(!aceptoPoliticas)}
							/> Acepto el aviso legal y las política de privacidad. 
						</div>
						<div class="">
							
							<button
								class="btn_enviar"
								disabled={!aceptoPoliticas}
								onClick={enviarCorreo}
								>
								Enviar
								</button>

						</div>
					</div>
			</div>
		</div>
	</section>
    {/*
  <section className='ventajas_section'>

<div className='container'>
<div className='ventajas_title'>
    <h4>Ventajas</h4>
    <div className='ventajas_title_text'>
        <p>Estas son algunas de las ventajas que debes saber si estas pensando en obtener una página web</p>
    </div>
    
</div>
    <div className='ventajas'>
        <div className='box_ventajas'>
            <div className='item_ventaja'>
                <label>Accesibilidad:</label>
                <p> Disponibilidad las 24 horas, los 7 días de la semana, permitiendo que personas de todo el mundo accedan a la información en cualquier momento.</p>
            </div>
            <div className='item_ventaja'>
                <label>Comunicación Eficiente:</label> 
                <p>Facilita la comunicación efectiva con audiencias específicas y la difusión rápida de información relevante.</p>
            </div>
            <div className='item_ventaja'>
                <label>Credibilidad y Presencia Online:</label>
                <p>Establece una presencia digital que contribuye a la credibilidad y visibilidad de individuos, empresas u organizaciones.</p>
            </div>
            <div className='item_ventaja'>
                <label>Costos Reducidos:</label> 
                <p>En comparación con los métodos tradicionales de publicidad y comunicación, mantener una página web puede ser más rentable.</p>
            </div>
        </div>
        <div className='box_ventajas'>
            <div className='item_ventaja'>
                <label>Interacción y Retroalimentación:</label>
                <p>Proporciona una plataforma para interactuar con los usuarios, recibir comentarios y adaptarse a las necesidades de la audiencia.</p>
            </div>
            <div className='item_ventaja'>
                <label>Actualizaciones y Novedades:</label> 
                <p>Permite la actualización constante de información, manteniendo a los visitantes informados sobre cambios, noticias y eventos.</p>
            </div>
            <div className='item_ventaja'>
                <label>Marketing y Promoción:</label> 
                <p>Facilita estrategias de marketing digital, promoción de productos o servicios, y la expansión de la base de clientes.</p>
            </div>
            <div className='item_ventaja'>
                <label>Accesibilidad Global:</label> 
                <p>Disponibilidad las 24 horas, los 7 días de la semana, permitiendo que personas de todo el mundo accedan a la información en cualquier momento.</p>
            </div>
        </div>
    </div>
</div>
</section>
  */}
    <section className='herramientas_tecnologias'>
      <div className='container'>
          <div className='herramientas'>
              <div className='herramientas_text'>
                  <h4>HERRAMIENTAS Y TECNOLOGIAS</h4>
                  <div className='herramientas_text_content'>
                      <p>Nuestras habilidades y experiencia nos permiten ofrecer soluciones web a través de diversas tecnologías para el desarrollo de software, atractivas, robustas, seguras y escalables.</p>
                  </div>
                 

              </div>
              <div className='herramientas_img'>
                  <div className='herramientas_box'>
                      <img src={html} alt='html' />
                      <p>HTML</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={css} alt='css' />
                      <p>CSS</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={js} alt='java script' />
                      <p>JavaScript</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={react} alt='react' />
                      <p>React</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={node} alt='Node Js' />
                      <p>Node JS</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={postgresql} alt='PostgreSQL' />
                      <p>PostgreSQL</p>
                  </div>
                  <div className='herramientas_box'>
                      <img src={mysql} alt='mySQL' />
                      <p>MySQL</p>
                  </div>
              </div>
              <div className='herramientas_text'>                    
                  <div className='herramientas_text_content'>
                      <p></p>
                  </div>
              </div>
          </div>

      </div>
    </section>
    
    <Footer />
  </>
  )
}

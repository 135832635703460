import React from 'react'
import '../css/headers.css'

export default function headers() {
  return (
    <>
    <section className='headers'>
        <div className='container'>
            <div className='title'>
                <h1>Nosotros</h1>
                <p>Nuestro propósito es lo que nos hace levantarnos cada mañana, <br/> y esforzarnos por ofrecer un futuro más digital. </p>
            </div>
        </div>
    </section>
    </>
  )
}
